@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Bree+Serif|Open+Sans:300,400');
// VARS
$header-col: #520d0c;
$header-txtcol: #e0d2b1;
$header-height: 120px;
$open-sans: 'Open Sans', sans-serif;
$bree-serif: 'Bree Serif', serif;
$title-color: #c84d4b;
$bg-color: #f5f3f1;
$link-color: $title-color;

// UTILS
html, body {height: 100%;}
html {box-sizing: border-box;}
*, *::before, *::after {box-sizing: inherit;}
img {
  max-width: 100%;
  vertical-align: middle;
}
p, a {font-size: 16px;}
p, h1, h2, h3, h4, h5, h6 {
  font-family: $open-sans;
  line-height: 1.5;
}
p {
  font-size: 16px;
  color:#545454;
  line-height: 26px;
}
h2, h3, h4, h5, h6 {
  color: $title-color;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
}
a {
  display: inline-block;
  color: $link-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.body { background-color: $bg-color;}
.center { text-align: center;}
.mt2 { margin-top: 28px;}
.mb2 { margin-bottom: 28px;}
.link-button {
  display: inline-block;
  background-color: $title-color;
  color: white;
  padding: 7px 14px;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
}
.flex {
  display: flex;
  justify-content: space-between;
}

// LAZYLOAD CSS
.lazyload.active {
  animation-name: fadeIn;
  animation-duration: 0.3s;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

/****************/
/*RESPONSIVE NAV*/
/****************/
.sp-header {
  display: block;
  position: relative;
  z-index: 99;
  height: $header-height;
  background-color: $header-col;
  overflow:hidden;
  transition: 0.39s height cubic-bezier(0.4, 0.0, 1, 1); //accelerate
  &.active {
    height: 100%;
    transition: 0.45s height cubic-bezier(0.0, 0.0, 0.2, 1); //decelerate
  }
}
.header-inner {display: block;}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  width: 100%;
}
.logo__link {display: block;}
.logo__image {width: 100px;}
.ham-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: $header-height;
  width: 50px;
}
.ham-line {
  display: block;
  position: relative;
  width: 30px;
  height: 1px;
  background-color: $header-txtcol;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    height: 1px;
    width: 26px;
    background-color: $header-txtcol;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    height: 1px;
    width: 26px;
    background-color: $header-txtcol;
  }
  &.active {
    transform: rotate(45deg);
  }
  &.active::after {
    opacity: 0;
    visibility: hidden;
  }
  &.active::before {
    width: 30px;
    transform: rotate(-90deg) translate(-10px);
  }
}
.nav-menu {
  display: none;
  opacity: 0;
  transition: 0.2s opacity;
  padding: 4%;
  overflow: auto;
  height: calc(100% - 120px);
  &.active {
    opacity: 1;
  }
}
.nav-menu__title {
  display: block;
  text-decoration: none;
  font-size: 32px;
  font-family: $open-sans;
  color: $header-txtcol;
  margin-bottom: 16px;
}
.nav-ul__item {
  margin-bottom: 16px;
  padding-left: 16px;
}
.nav-ul__link {
  display: block;
  text-decoration: none;
  color: $header-txtcol;
  font-family: $open-sans;
}

/***To Top Button***/
.btn-top {
  display: inline-block;
  position: fixed;
  z-index: 100;
  bottom: 4vw;
  right: 4vw;
  width: 50px;
  height: 50px;
  transform: translateY(200%);
  transition: 0.2s transform cubic-bezier(0.4, 0.0, 1, 1); //accelerate
  &.active {
    transform: translateY(0);
    transition: 0.23s transform cubic-bezier(0.0, 0.0, 0.2, 1); //decelerate
  }
}
.btn-top__img {opacity: 0.6;}
/***To Top Button END***/

// POST TITLE
.post-title {
  font-family: $bree-serif;
  font-size: 32px;
  padding: 4%;
  line-height: 1.4;
  color: $title-color;
  &::after {
    content: "";
    display: block;
    margin-top: 16px;
    height: 1px;
    background-color: $title-color;
    width: 100px;
  }
}
.post-title--fourofour {
  text-align: center;
  &::after {
    content: none;
    display: none;
  }
}
.post-title--nopadding {
  padding: 0 4%;
}
.post-title--category {
  padding: 0 4%;
  &::after {
    margin-top: 8px;
  }
}
.post-wrap__inner {
  background-color: white;
  padding: 0 0 1px 0;
  box-shadow: 0px 0px 30px -10px #695b5b, 0px 5px 20px rgba(0, 0, 0, 0.1);
}
.post-content {
  p, h2, h3, h4, h5, h6 {
    padding: 0 4%;
    line-height: 26px;
    margin: 0 auto 26px auto;
  }
  p {
    > img {
      margin: 26px 0;
    }
  }
}
.post-block {
  margin: 26px 0;
  &:first-child {
    margin: 0 0 26px 0;
  }
}
.post-block--category {
  margin: 16px 0;
}

.list-item {
  padding: 4%;
}

//POST ITEM
.post-item {
  background-color: white;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
}
.post-item__txtwrap {
  padding: 16px;
}
.post-item__link {
  font-size: 1em;
  font-weight: 300;
  color: #545454;
  line-height: 32px;
}
.post-item__date {
  font-size: 0.707em;
  line-height: 20px;
  color: $link-color;
}
.post-item__readwrap {
  margin-right: 9px;
  line-height: 20px;
}
.post-item__read {
  position: relative;
  background-color: #c84d4b;
  color: white;
  font-size: 0.707em;
  line-height: 18px;
  padding: 0 8px;
  border-radius: 4px 0 0 4px;
  vertical-align: top;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -9px;
    height: 0;
    width: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 9px solid #c84d4b;
  }
}

.post-mainMap {
  height: 400px;
}

// POST DOODLE
.post-doodle {padding: 4%;}

// FOOTER
.footer {
  text-align: center;
  padding: 26px 0;
}
.footer__text {
  color: #a87271;
}

// 404
.rolled-away {
  color: #b5b1ad;
  text-align: center;
}

// CATEGORY CORNER
.category-smtxt {
  padding: 0 4%;
}
