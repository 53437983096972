@media screen and (min-width: 1200px) {
  // CARD LAYOUT
  .list-wrap {
    max-width: 1200px;
  }
  .list-item {
    width: 23.5%;
    &:nth-child(3n+0) {
      margin-right: 2%;
    }
    &:nth-child(4n+0) {
      margin-right: 0;
    }
  }
}
